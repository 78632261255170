.flex-1 {
  flex: 1;
}

.zz-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 20px;
  background-color: #000000;
  color: #ffffff;
  font-size: 20px;
}

.zz-navbar__logo {
  display: inline-block;
  padding-right: 24px;
  margin-right: 24px;
  border-right: solid 1px white;
  font-size: 26px;
  font-weight: bold;
}

.zz-navbar__rest {
  margin-right: auto;
}

.zz-navbar__items {
  font-size: 16px;
}

.zz-navbar__items > * {
  margin-left: 24px;
  color: white;
  padding: 4px;
}

.zz-navbar__items > a:hover {
  color: white;
  border-bottom: solid 2px #f5a623;
  text-decoration: none;
}

.zz-navbar__item--active {
  border-bottom: solid 2px white;
}

#loading-screen {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 1;
  transition: 1s opacity;
}

#loading-screen.fade-out {
  opacity: 0;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #9370db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ba55d3;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ff00ff;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loader-progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 3;
}