html,
body {
  height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.zz-flex-1 {
  flex: 1;
}

.zz-d-flex-column-1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}
